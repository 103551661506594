.contentClassName {
    border-radius: 20px !important;
    padding: 20px;
}

.headerContainer {
    display: flex;
    align-items: center;

}



.headerItemInfoContainer {
    margin: 0px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
    width: 250px;
    margin: auto;
    position: relative;
}
.editImageContainer{
    position: absolute;
    bottom: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    cursor: pointer;
    border-radius: 50%;
    background-color: #E37230;
    right: 10px;
}
.editIcon{
    color: white;

}
.pieceImage {
    height: 250px;
    width: 250px;
    border-radius: 10px;
    object-fit: cover;

}

.pieceNameText {
    font-size: 18px;
    font-weight: 700;
    color: black;
    margin: 0;
}

.infoMessage{
    font-size: 12px;
    font-weight: 500;
}

.pieceUnityText {
    font-size: 13px;
    font-weight: 700;
    margin: 0px 10px 0px 10px;
    color: black;
}

.pieceIdText {
    font-size: 14px;
    font-weight: 500;
    color: #5e5e5e;
    margin: 0;
}


.modalFooter {
    display: flex;
    align-items: center;
    margin-top: 20px;
    justify-content: center;
}

.mainContentContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalBodyContainer {
    width: 100%;
}

.itemContainer {
    margin: 10px 0px;
}

.itemText {
    margin: 5px 0;
    font-size: 15px;
    color: black;
    font-weight: 500;
}

.modalButtonAccept {
    margin: 0px 5px 0px 5px;
    border-radius: 10px;
    font-size: 13px;
    font-weight: 600;
}

.modalButtonRefuse {
    margin: 0px 5px 0px 5px;
    border-radius: 10px;
    font-size: 13px;
    background-color: rgb(211, 0, 0);
    font-weight: 600;
}

.modalButtonRefuse:hover {
    background-color: red;
}