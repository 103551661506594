.mainContainer{
    bottom: 0px;
    left: 0;
    width: 100%;
    border-radius: 10px;
    z-index: 999;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #17180F;
}

.musicControlsContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
}

.musicControlIcon{
    font-size: 20px;
    color: white;
}
.iconContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 35px;
    cursor: pointer;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 50%;
    background-color: transparent;
}
.iconContainer:hover
.musicControlIcon{
    color: #ff6200;

}
.iconContainer:hover{
    background-color:rgba(243, 157, 76, 0.3);
}


.waveMusicControlsMainContainer{
    display: flex;
    justify-content: center;
    padding: 0;

    align-items: center;
}

.musicControlsMainContainer{
    display: flex;
    justify-content: center;
    padding: 0;
    margin-top: 5px;
    margin-bottom: 5px;
    align-items: center;
}

.previewInfoContainer{
    display: flex;
    align-items: center;

}

.previewImage{
    height: 35px;
    width: 35px;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 10px;
    object-fit: cover;
}

.previewTitle{
    font-size: 12px;
    color: white;
    margin: 0;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 800;
}

.previewIconContainer{
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    margin-right: 5px;
}

.shareContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    right: 25px;
    bottom: -15px;
    transition: all linear 0.3s;
    border-radius: 10px;
    height: 50px;
    background-color: #17180f !important;
    z-index: 9999;   
}

@media screen and (max-width: 1199px) {
    .mainContainer {
      height: 150px;
    }
    .musicControlsMainContainer{
        justify-content: center;
    }
  }


  @media screen and (max-width: 767px) {
 
    .waveMusicControlsMainContainer{
        height: 80px;
    }
    .musicControlsMainContainer{
        justify-content: center;
    }
}
@media screen and (max-width: 991px) {
    .waveMusicControlsMainContainer{
        height: 80px;
    }
    .musicControlsMainContainer{
        justify-content: center;
    }
  }