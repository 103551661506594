
.timerContainer{

    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
}
.timerText{
    white-space: nowrap;
    font-size: 12px;
    font-weight: 500;
    color: white;
    margin: 0;
}