.mainContainer {
    margin: 10px 0px 10px 0px;
}

.mainNavItemContainer {
    display: flex;
    padding: 10px;
}

.mainNavItemContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}