.mainContainer {
    height: 100vh;
    width: 100%;
    background-color: #1B2039;
    display: flex;
    align-items: center;
    justify-content: center;
}


.logoImg {
    width: 400px;
}