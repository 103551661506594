.mainItemContainer {
    margin: 20px 0;
}

.searchInput {
    border: 1px solid #999999;
    width: min-content;
    min-width: 200px;
    font-size: 14px;
    margin-right: 20px;

}

.headerContainer {
    display: flex;
    align-items: center;
}

.detailedSearchText {
    font-size: 16px;
    font-weight: 500;
    color: black;
    margin: 0;
}

.iconSearchDetails {
    color: black;
    font-size: 20px;
    margin-left: 10px;
}

.numberOfItemsText {
    color: black;
    font-size: 17px;
    font-weight: 600;
}