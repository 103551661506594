.mainContainer {
    height: 100vh;
    background-color: #14182A;
}

.mainRow {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;


}

.mainCol {
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: inset rgba(255, 255, 255, 0.1) 0px 2px 0px,
        inset rgba(255, 255, 255, 0.04) 0px 0px 0px 2px,
        rgba(0, 0, 0, 0.2) 0px 2px 10px;

}

.mainTitle {

    font-size: 17px;
    text-align: center;
    color: black;
    margin: 0;
    font-weight: 800;
}

.itemContainer {
    margin: 10px 0px;
}

.itemContainerError {
    margin: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.itemText {
    margin: 5px 0;
    font-size: 15px;
    color: black;
    font-weight: 500;
}

.itemTextError {
    margin: 5px 0;
    font-size: 13px;
    color: red;
    font-weight: 500;
}


.loginButton {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    width: 100%;
    font-size: 14px;
    border-radius: 20px;
}

.logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.logoImage {
    width: 400px;
}

.loginContainer {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}