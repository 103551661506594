.searchIcon {
    position: absolute;
    top: calc(50% - 14px/2);
    font-size: 14px;
    color: #999999;
    left: 10px;
}

.inputContainer {
    position: relative;
    width: 100%;
}

.searchInput {
    border: 1px solid #999999;
    width: min-content;
    min-width: 200px;
    width: 100%;
    font-size: 14px;
    margin-right: 20px;
    padding: 10px 10px 10px 30px !important;


}