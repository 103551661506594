.badgeContainer {
    height: 15px;
    width: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    border-radius: 50%;
    position: absolute;
    bottom: 5px;
    right: 10px;
    background-color: red;
}

.badgeText {
    color: white;
    font-size: 15px;
    font-weight: 700 !important;

}

.notificationItemText {
    white-space: pre-line;
    color: black;
    padding-top: 5px;
    padding-bottom: 5px;
}

.notificationItemContainer {
    padding-bottom: 0;
    display: flex;
    align-items: center;
    margin: 0 !important;
    width: 300px;
    justify-content: space-between;
}

.notificationItemDate {
    font-size: 11px !important;
    color: #6e6e6e;
    max-width: 60px;
    padding-left: 10px;
    white-space: pre-line;
}

.menuControlItem {
    color: black;
}

@media (max-width: 991px) {
    .badgeContainer {
        bottom: calc(50% - 7px);
        right: 10px;
    }

    .notificationItemText {
        color: white;
    }

    .notificationItemContainer {
        padding-bottom: 0;
        width: 100%;
    }

    .menuControlItem {
        color: white;
    }

}