.saveButton {
    color: white;
    font-weight: 600;
    font-size: 13px;
    border-radius: 10px;
    display: flex;
    align-items: center;
}


.disabled {
    cursor: auto;
    background-color: gray;
}