.form-control {
    border: 1px solid rgba(0, 0, 0, 0.42) !important;


}

input{
    padding: 10px 20px 10px 10px !important;
}

.form-control:focus {
    background-color: #fff;
    border: 2px solid #E37230 !important;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(36, 116, 241, 0.1);
}
.MuiCheckbox-colorSecondary.Mui-checked{
    color: #E37230 !important;
}
.MuiInput-underline:before {
    border-radius: 20px !important;
    border: 1px solid rgba(0, 0, 0, 0.42) !important;
    top: 0 !important;
}

.MuiInput-underline:after {
    border-bottom: 2px solid transparent !important;
}

.MuiSelect-select.MuiSelect-select {
    /* padding: 7px 10px 7px 10px !important; */
    padding: 10px 20px 10px 10px !important;
    border-radius: 20px !important;
}



.btn {
    font-size: 13px;
    font-weight: 600;
    border-radius: 10px;

}

.dropdown-menu {
    opacity: 1 !important;
    max-height: 300px;
    overflow-y: auto;
}

.Mui-focused {
    background-color: #fff !important;
    border: 2px solid #E37230 !important;
    outline: 0 !important;
    border-radius: 25px !important;
    box-shadow: 0 0 0 0.2rem rgba(36, 116, 241, 0.1) !important;
}

textarea {
    background-color: transparent !important;
    border-radius: 20px !important;
    border: 1px solid rgba(0, 0, 0, 0.42) !important;
    resize: none !important;

}

textarea:focus {
    background-color: #fff !important;
    border: 2px solid #E37230 !important;
    outline: 0 !important;
    border-radius: 20px !important;
    box-shadow: 0 0 0 0.2rem rgba(36, 116, 241, 0.1) !important;
}