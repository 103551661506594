.deleteContainer {
    background-color: red;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 30px;
    cursor: pointer;
    width: 30px;
    display: flex;
}


.categoryInfoContainer{
    display: flex;
    align-items: center;
}
.categoryImage{

    height: 40px;
    width: 40px;
    border-radius: 5px;
    object-fit: cover;
}

.categoryName{
    font-size: 14px;
    margin: 0;
    margin-left: 10px;
}