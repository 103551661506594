.mainContainer{
    padding: 30px 15px 30px 15px;
}
.titleContainer{
    margin-bottom: 20px;
    padding: 30px 15px 30px 15px;
}


.pageTitle{
    font-size: 19px;
    margin: 0;
    font-weight: 800;
}
.infoTitle{
    font-size: 15px;
    margin: 0;
    font-weight: 600;
}
.infoValue{
    font-size: 15px;
    margin: 0;
    font-weight: 500;
}
.tableCellTitle{
    font-size: 13px;
    margin: 0;
}


.badgeContainer{
    margin-left: 10px;
    margin-top: 10px;
    background-color: green;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: min-content;
    padding: 5px 10px 5px 10px;
}

.badgeText{
    font-size: 13px;
    font-weight: 800;
    color: white;
    margin: 0;
}